.apo-last-posts {

    hr {
        position: relative;
        bottom: rem-calc(20);
    }

    .apo-last-posts__show-more {
        margin-top: rem-calc(50);
        text-align: center;

        a {
            .show-on-active {
                display: none;
                
            }
            
            &.all-cells-visible {
                .show-on-active {
                    display: initial;
                }
    
                .hide-on-active {
                    display: none;
                }
            }
        }
    }

    .cell {
        padding-top: 0;
        margin-top: -3px;
    }

    .additional-cell {
        display: none;
    }

    .post-item {
        .post-item__date {
            margin-top: auto;
        }
    }

    .post-item--apo_campaign {
        background-color: $black;
        color: white;

        // .post-item__title {
        //     padding-top: rem-calc(20);
        // }
        // .post-item__thumbnail {

        //     &:after {
        //         content: '';
        //             bottom: -5px;
        //     }
        //     &:before {
        //         content: '';
        //             position: absolute;
        //             display: block;
        //             height: 5px;
        //             width: 100%;
        //             background-color: $white;
        //             bottom: -5px;
        //             left: 0;
        //     }

        // }
    }
}