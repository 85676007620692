.apo-last-entry {
    margin-bottom: map_get($grid-column-gutter, medium);

    .apo-last-entry__wrapper {
        position: relative;
        padding: map_get($grid-column-gutter, medium) 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            width: 60%;
            height: 2px;
            background-color: $primary-color;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    .apo-last-entry__heading {
        font-size: $font-size-medium;
        line-height: $line-height-medium;
        color: $primary-color;
        font-family: $header-font-family-alt;
        text-transform: uppercase;
        margin-bottom: $font-size-normal;
    }
    
    .apo-last-entry__title {
        font-family: $body-font-family;
        font-weight: 300;
        font-size: $font-size-large;
        line-height: $line-height-large;

        a {
            text-decoration: none;
            color: $black;

            &:hover {
                font-weight: 300;
                text-decoration: underline;
            }
        }
    }

}