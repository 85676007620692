@keyframes fade-right {
    0% {
        transform: translateX(-5px);
        opacity: 0;
    }
    50% {
        transform: translateX(5px);
        opacity: 1;
    }
    100% {
        transform: translateX(-5px);
        opacity: 0;
    }
}

.fade-right {
    animation: 1.5s ease-out fade-right;
    animation-iteration-count: infinite;
}