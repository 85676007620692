// ol.lower-latin {
//     counter-reset: item;

//     li {
//         display: block;

//         &:before {
//             content: counter(item, lower-latin) ") ";
//             counter-increment: item;
//             color: $primary-color;
//         }
//     }

// }

// ol.lower-roman {
//     counter-reset: item;

//     li {
//         display: block;

//         &:before {
//             content: counter(item, lower-roman) ". ";
//             counter-increment: item;
//             color: $primary-color;
//         }
//     }

// }

@include list(ol, lower-latin, ') ', $primary-color);
@include list(ol, lower-roman, ') ', $primary-color);
@include list(ol, decimal, '. ', $primary-color);
@include list(ul, long-dash, '− ', $primary-color);
@include list(ul, right-arrow, '> ', $primary-color);

ol.lower-latin,
ol.lower-roman {

    >li {

        margin-bottom: 1.5em;

        &:before {
            left: -1.5em;
        }
    }
}

ul.long-dash {

    >li {

        margin-bottom: 0.3em;
        margin-top: 0.2em;

        &:before {
            left: -1.5em;
        }
    }
}

ul.right-arrow {
    
    >li {
        
        padding-left: 1.25em;
        margin-bottom: 0.3em;
        margin-top: 0.2em;

        &:before {
            left: -1em;
            width: 0.25em;
            margin-left: -0.25em;
        }
    }
}