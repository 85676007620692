.apo-cta {
    
    margin-bottom: map_get($grid-column-gutter, medium);

    .apo-cta__title {
        font-size: $font-size-medium;
        line-height: $line-height-medium;
        color: $primary-color;
        font-family: $header-font-family-alt;
        text-transform: uppercase;
        margin-bottom: $font-size-normal;
    }

    .apo-cta__body {
        margin-bottom: $font-size-small;
    }

    .apo-cta__link {
        text-transform: uppercase;
        color: $secondary-color;
        font-family: $header-font-family-alt;
    }

}

.apo-cta--box {

    position: fixed;
    top: 50%;
    left: 10%;
    background-color: $primary-color;
    padding: rem-calc(18);
    max-width: 350px;
    cursor: move;
    box-shadow: 5px 5px 23px rgba(0,0,0,0.21);
    z-index: 10;

    .apo-cta__close {
        display: block;
        width: rem-calc(18);
        height: rem-calc(18);
        line-height: rem-calc(18);
        font-size: rem-calc(18);
        margin-left: auto;
        margin-bottom: rem-calc(15);

        &:hover {
            .st0 {
                stroke: $white;
            }
        }
    }

    h5 {
        font-size: rem-calc(35);
        line-height: rem-calc(32);
        margin-bottom: rem-calc(25);
    }

    .apo-cta__link {
        display: inline-block;
        font-size: rem-calc(20);
        line-height: rem-calc(20);
        padding: rem-calc(13) rem-calc(20);
        text-transform: uppercase;
        text-decoration: none;
        color: $black;
        border: 2px solid $black;
        background-color: transparent;
        transition: all 300ms;
        margin-bottom: rem-calc(13);

        &:hover {
            background-color: $black;
            color: $white;
        }
    }

}
