table {

    table-layout: fixed;
    margin-top: $font-size-medium;
    margin-bottom: $font-size-large;
    
    &.not-fixed {
        table-layout: initial;
    }

    thead {
        background-color: initial;
        border-color: $primary-color;
        border-style: solid;
        border-width: 2px 0;

        tr {

            th {
                text-transform: uppercase;
                font-family: $header-font-family;
                font-weight: bold;
                font-size: $font-size-xsmall;
                line-height: 1.2;
                color: $primary-color;
                padding: $font-size-small $font-size-medium $font-size-small 0;
                
                @include breakpoint(small only) {
                    padding: $font-size-small;
                    padding-left: 0;
                }
            }
        }
        
    }
    
    tbody {
        
        border: 0;
        border-bottom: 2px solid $black;
        
        tr {
            
            &,
            &:nth-child(even),
            &:nth-child(odd) {
                border-bottom: 1px solid $black;
                background-color: initial;
            }
            
            &:last-of-type {
                border-bottom: 0;
            }
            
            td {
                
                font-size: $font-size-xsmall;
                line-height: 1.2;
                padding: $font-size-xsmall;
                padding-left: 0;
                
                &:first-of-type {
                    // font-weight: bold;

                    p {
                        font-weight: normal;
                    }
                }


            }

        }
    }

    p {
        margin-bottom: rem-calc(5);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

}

#quadre-processos {

    thead {
        border: 0;

        tr {

            th {

                text-align: center;
                
                &:nth-of-type(1) {
                    width: 5%;
                }
                
                &:nth-of-type(2),
                &:nth-of-type(3) {
                    width: 47.5%;
                    border-color: $primary-color;
                    border-style: solid;
                    border-width: 2px 0;
                }

            }

        }

    }

    tbody {

        tr {
            // border-bottom: 0;

            td {
                padding: $font-size-xsmall;
                // border-bottom: 1px solid $black;

                &.highlight {
                    position: relative;
                    font-family: 'GT America', Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    background-color: $light-gray;
                    // border-bottom: 0;

                    span {
                        white-space: nowrap;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        text-align: center;
                        transform: translate(-50%, -50%) rotate(-90deg);
                    }
                }

            }

            &:last-of-type {

                td {
                    border-bottom: 0;
                }
            }

        }
    }

}

