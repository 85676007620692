$post-padding-top: 45px;

.blog-archive {

    padding-top: $post-padding-top;

}

.blog-archive__header {

}

.blog-archive__title {
    font-family: $blockquote-font-family;
    color: $secondary-color;
    font-size: $font-size-xlarge;
    line-height: $line-height-xlarge;  
}

.blog-archive__filters {

    padding-bottom: 80px;

    .search-form {

        display: flex;

        label {
            color: $white;
            font-size: $font-size-xlarge;
            white-space: nowrap;
        }

        .input-group {
            flex-grow: 1;
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        input {
            color: $white;
            background-color: transparent;
            font-size: $font-size-xlarge;
            border: 0;
            outline: none;

            &:focus {
                box-shadow: none;
            }
        }

        input[type=search] {
            border-bottom: 1px solid $white;
            margin-bottom: 0;
            margin-left: 10px;
        }

        input[type=submit] {
            text-decoration: underline;
            margin-left: $font-size-medium;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }
    }

}

.post-item {

    .post-item__wrapper {
        height: 100%;
    }
    
    .post-item__content {
        display: block;
        background-color: $white;
        text-decoration: none;
        height: 100%;
        padding: 1rem;

        &:hover {
            text-decoration: none;
            font-weight: initial;
        }
    }


    .post-item__category {
        text-transform: uppercase;
        font-size: $font-size-normal;
        line-height: 1.2;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .post-item__thumbnail {
        margin-bottom: $font-size-small;
    }

    .post-item__date {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: $font-size-small;
        margin-bottom: $font-size-small;
        font-weight: 500;
    }

    .post-item__title {
        font-size: $font-size-normal;
        color: $primary-color;
        margin-bottom: $font-size-small;
        font-weight: 500;
        text-transform: uppercase;
    }

    .post-item__body {
        color: $black;
        font-size: $font-size-small;
        margin-left: 0;
        font-weight: 300;
        margin-bottom: 0;

        li {
            list-style: none;
        }
    }

    .post-item__location {
        color: $black;
        font-size: $font-size-small;
        font-weight: 300;
    }
}

.post {

    padding-top: 0;
    // padding-bottom: 0;

    .post__header {
        background-color: $white;
        padding: $font-size-xlarge * 2 0;
        margin-bottom: $font-size-xlarge * 2;
    }

    .post__intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .post__title {
        font-size: $font-size-xlarge;
        line-height: $font-size-xlarge;
        font-family: $body-font-family;
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 300;
    }
    
    .post__date {
        color: $secondary-color;
        font-weight: 300;
        font-size: $font-size-medium;
        line-height: $font-size-medium;
    }

    .post__content {
        font-size: $font-size-normal;
        line-height: $font-size-normal;
        padding-bottom: $font-size-xlarge;

        blockquote {
            font-size: $font-size-xlarge;
            line-height: $line-height-xlarge;
            border: 0;
            color: inherit;

            p {
                color: inherit;
                letter-spacing: -2px;

                &:before {
                    content: '“'
                }

                &:after {
                    content: '”'
                }

            }

        }
    }

    .post__footer {
        font-size: $font-size-xlarge;
        line-height: $line-height-xlarge;

        
        .post__archive-link {
            color: $secondary-color;
            font-size: $font-size-medium;
            
            i {
                font-size: $font-size-normal;
                display: inline-block;
                text-decoration: none;
                margin-right: 10px
            }
        }
    }

    .post__navigation {

        display: flex;
        justify-content: space-between;
        padding: 100px 0;

        .post__prev {
            &:before {
                content: '\2190\0020';
                font-feature-settings: "ss03"
            }
        }

        .post__archive {
            &:before {
                content: '\2191\0020';
                font-feature-settings: "ss03"
            }
        }

        .post__next {
            &:after {
                content: '\0020\2192';
                font-feature-settings: "ss03"
            }
        }

    }

    .post__share {

        // text-align: center;
        font-size: $font-size-normal;
        line-height: $line-height-normal;
        border-top: 1px solid $secondary-color;
        padding: $font-size-large 0;

        .share__label {
            margin-bottom: 0.5rem;
        }

        .share__links {
        }

    }

    .post__rating {

        display: flex;

        .watch-action {
            float: none;
            width: initial;
            height: initial;

            .watch-position {
                float: none;
                width: initial;
                display: flex;

                .action-like,
                .action-unlike {
                    display: flex;
                    float: none;
                    width: initial;
                }
            }

            a {
                text-decoration: none;

                &:hover {
                    font-weight: 200;

                    .action-label {
                        font-weight: bold;
                    }

                }
            }

            .action-label {
                text-decoration: underline;
            }

            .lc, .unlc {
                float: none;
                width: initial;
                font-size: inherit;
                line-height: inherit;
                color: inherit;
            }

            .status {
                padding: 10px 0;
                font-size: $font-size-small;
                line-height: $font-size-small;
                color: rgba($white, 0.75)
            }
        }

    }

    .mode-panel {

        position: fixed;
        display: flex;
        top: $post-padding-top;
        right: $font-size-medium;

        .mode-panel__switch {
            width: $font-size-medium;
            height: $font-size-medium;
            margin: 0 ($font-size-small / 2);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 300ms;
            font-size: 0;

            cursor: pointer;

            &.is-active {
                cursor: auto;
            }

            svg {
                height: 0%;
                opacity: 0;
                transition: all 300ms;
            }

            &.mode-panel__switch--on {
                background-color: $white;
                border: 1px solid $black;
                color: $black;

                svg {
                    * {
                        fill: $black;
                    }
                }
            }

            &.mode-panel__switch--off {
                background-color: $black;
                border: 1px solid $white;
                color: $white;

                svg {
                    * {
                        fill: $white;
                    }
                }
            }

            &:not(.is-active):hover {
                transform: scale(1.5);
                font-size: $font-size-small;

                svg {
                    opacity: 1;
                    height: 50%;
                }
            }
        }

    }

}

.progress-bar {
    position: fixed;
    height: 1px;
    background-color: $white;
    width: 0;
    z-index: 100;
    // transition: width 100ms;
}

body.day-mode {

    .progress-bar {
        background-color: $black;
    }

}
