@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: normal;
	src: url("fonts/Roboto-Regular.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: normal;
	src: url("fonts/Roboto-Italic.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: bold;
	src: url("fonts/Roboto-Bold.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: bold;
	src: url("fonts/Roboto-BoldItalic.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: url("fonts/Roboto-Medium.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	src: url("fonts/Roboto-MediumItalic.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: url("fonts/Roboto-Light.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	src: url("fonts/Roboto-LightItalic.ttf");
	// src: url("fonts/Roboto-Regular.eot");
	// src: url("fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
	//      url("fonts/Roboto-Regular.woff2") format("woff2"),
	//      url("fonts/Roboto-Regular.woff") format("woff"),
	//      url("fonts/Roboto-Regular.ttf") format("truetype"),
}
@font-face {
	font-family: "News Gothic BT Condensed";
	font-style: normal;
	font-weight: bold;
	src: url("fonts/NewsGothicBT-BoldCondensed.otf");
	src: url("fonts/NewsGothicBT-BoldCondensed.eot");
	src: url("fonts/NewsGothicBT-BoldCondensed.eot?#iefix") format("embedded-opentype"),
	     // url("fonts/NewsGothicBT-BoldCondensed.woff2") format("woff2"),
	     url("fonts/NewsGothicBT-BoldCondensed.woff") format("woff"),
	     url("fonts/NewsGothicBT-BoldCondensed.ttf") format("truetype"),
}
@font-face {
	font-family: "News Gothic BT Roman Condensed";
	font-style: normal;
	font-weight: normal;
	// src: url("fonts/NewsGothicBT-RomanCondensed.otf");
	src: url("fonts/NewsGothicBT-RomanCondensed.eot");
	src: url("fonts/NewsGothicBT-RomanCondensed.eot?#iefix") format("embedded-opentype"),
	     url("fonts/NewsGothicBT-RomanCondensed.woff2") format("woff2"),
	     url("fonts/NewsGothicBT-RomanCondensed.woff") format("woff"),
	     url("fonts/NewsGothicBT-RomanCondensed.ttf") format("truetype"),
}
@font-face {
	font-family: "Amerigo BT RomanA";
	font-style: normal;
	font-weight: normal;
	// src: url("fonts/AmerigoBT-RomanA.otf");
	src: url("fonts/AmerigoBT-RomanA.eot");
	src: url("fonts/AmerigoBT-RomanA.eot?#iefix") format("embedded-opentype"),
	     url("fonts/AmerigoBT-RomanA.woff2") format("woff2"),
	     url("fonts/AmerigoBT-RomanA.woff") format("woff"),
	     url("fonts/AmerigoBT-RomanA.ttf") format("truetype"),
}



h1,h2,h3,h4,h4,h6 {
	letter-spacing: -0.026em;
}

h1 {
	line-height: rem-calc(73);

	@include breakpoint (small only) {
		line-height: rem-calc(50);
	}
}

a {
	color: $primary-color;
	text-decoration: underline;

	&:hover,
	&:focus {
		color: $primary-color;
		font-weight: 400;
		// text-decoration: none;
		// font-size: 0.96em;
	}
}

*.text-alt {
	font-family: $header-font-family-alt;
}

a.highlight {

	color: $primary-color;
	text-decoration: underline;
	font-size: rem-calc(23);
	line-height: rem-calc(23);

}

strong {
	word-break: break-all;
}

strong.highlight {
	color: $primary-color;
}

// ol {
// 	list-style: none;
// 	counter-reset: li;
// 	margin-left: 2em;

// 	li {
// 		counter-increment: li;

// 		&:before {
// 			content: (counter(li));
// 			color: $primary-color;
// 			display: inline-block;
// 			width: 1em;
// 			margin-left: -1em
// 		}
// 	}
// }

.superindex {
    position: relative;
	font-size: 0.7em;
    top: -0.8em;
}
