.menu {

    li {
        font-size: $font-size-small;
        text-transform: uppercase;
        font-family: $header-font-family-alt;
        font-weight: bold;
        text-decoration: none;

        >a {

            &:hover {
                text-decoration: underline;
                color: $secondary-color;
            }
        }

        &.current-menu-item,
        &.current-menu-ancestor {

            >a {
                color: $secondary-color;
                // font-weight: bold;
                &:hover {
                    text-decoration: none;
                }
            }

        }
    }

    >li {

        display: flex;
        align-items: center;
    }

    .submenu {
        
        opacity: 0;
        visibility: hidden;
        transition: all 300ms;
        
        &.is-active {
            opacity: 1;
            visibility: visible;
        }

    }


}

.menu--submenu {
    display: flex;
    flex-direction: column;
}

.menu--macro {

    position: initial;

    .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $primary-color;
        border-bottom: 1px solid $secondary-color;
        
        a {
            color: $body-background;
        }

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3rem;
            left: 0;
            top: -3rem;
        }
    }


    .submenu__wrapper {
        padding: $font-size-xlarge 0;
    }

    .submenu__panel {
        border-left: 1px solid $secondary-color;
    }

}

.is-stuck {
    .menu--macro {
    
        .submenu {
    
            &:before {
                height: 1rem;
                top: -1rem;
            }
        }
    }
}

.social-menu {
    display: flex;

    .menu {

        li {
            text-transform: uppercase;
            font-size: $font-size-xsmall;

            a {
                padding: 0 5px;
                text-decoration: none;

                &:hover {
                    font-family: $body-font-family;
                }

                svg {
                    height: $font-size-medium;
                    width: $font-size-medium;

                    * {
                        fill: $secondary-color;
                    }
                }
            }

        }

        &.is-dropdown-submenu {
            min-width: initial;
            right: -2px;
            left: -1px;
        }
    }
}

.menu--mobile {
    width: 100%;

    >li {
        flex-flow: column nowrap;
    }

    .submenu {
        width: 100%;
        padding-left: map_get($grid-column-gutter, medium) / 2;
    }

    .menu-item {

        >a {
            width: 100%;
        }
    }
}
