.courses {
    padding-top: map-get($grid-column-gutter, medium);
}

.courses__title {
    color: $primary-color;
    text-align: center;
    font-size: $font-size-xxlarge;
    font-family: $header-font-family-alt;
    font-weight: bold;
    text-transform: uppercase;
}

.course-item--stack {
    color: $primary-color;

    .course-item__month {
        color: $secondary-color;
        font-weight: 500;
        font-size: $font-size-large;
        line-height: $font-size-large;
        margin-bottom: 1rem;
    }

    .course-item__separator {
        // border-top: 1px dashed $primary-color;
    }

    .course-item__content {
        background-image: linear-gradient(to right, $primary-color 50%, rgba(255,255,255,0) 0%);
        background-position: top left;
        background-size: 10px 1px;
        background-repeat: repeat-x;
        // height: 1px
        padding-top: map-get($grid-column-gutter, medium) / 2;
        padding-bottom: map-get($grid-column-gutter, medium) / 2;
    }

    .course-item__date {
        font-size: $font-size-medium;
    }

    .course-item__title {
        font-size: $font-size-medium;
        line-height: 1.5;
        margin-bottom: 0;
    }

    .course-item__hour {
        margin-bottom: 0;
    }

    .course-item__more {
        text-transform: uppercase;
        text-align: right;
    }
}

.course-item--card {
    display: block;
    background-color: $white;
    padding: 1rem;
    text-decoration: none;
    height: 100%;

    &:hover {
        text-decoration: none;
        font-weight: initial;
    }

    .course-item__category {
        text-transform: uppercase;
        font-size: $font-size-normal;
        line-height: 1.2;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .course-item__thumbnail {
        margin-bottom: $font-size-small;
    }

    .course-item__signup {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: $font-size-small;
        margin-bottom: $font-size-small;
        font-weight: 500;
    }

    .course-item__title {
        font-size: $font-size-normal;
        color: $primary-color;
        margin-bottom: $font-size-large;
        font-weight: 400;
    }

    .course-item__sessions {
        color: $primary-color;
        font-size: $font-size-small;
        margin-left: 0;
        font-weight: 300;
        margin-bottom: 0;

        li {
            list-style: none;
        }
    }

    .course-item__location {
        color: $black;
        font-size: $font-size-small;
        font-weight: 300;
    }
}

.courses__empty {
    text-align: center;
    color: $primary-color;
    font-size: $font-size-medium;
    padding: $font-size-xlarge 0;
    
    h3 {
        font-weight: 300;
    }
}

.courses__navigation {
    display: flex;
    justify-content: center;
    font-size: $font-size-medium;
    line-height: $font-size-medium;
    font-weight: 400;
    text-transform: uppercase;

    a {
        color: $secondary-color;
        padding: $font-size-large;
        text-decoration: none;

        &:hover {
            font-weight: 500;
        }

        i {
            // display: inline-block;
            font-size: $font-size-normal;
            padding: 0 10px;
            text-decoration: none;
        }
    }
}

.courses__gallery {

    // background-color: $primary-color;
    padding: $font-size-xlarge 0;

    h2 {
        font-size: $font-size-xlarge;
        line-height: $line-height-xlarge;
        color: $secondary-color;
        font-family: $blockquote-font-family;
    }

}

.post--apo_course {

    .post__header {
        background-color: transparent;
        margin-bottom: 0;
    }

    .post-metadata {

        padding-top: $font-size-xlarge;
        padding-bottom: $font-size-xlarge;
        border-bottom: 1px solid $secondary-color;

        p {
            margin-bottom: 0;
            // color: $primary-color;
        }

        .post-metadata__item {
            margin-bottom: 1rem;
        }
    }

}
