.apo-quote {
    font-size: $font-size-small;
    line-height: $font-size-small;
    
    .apo-quote__content {
        padding: 15px;
        border: 2px solid $primary-color;

        .apo-quote__title {
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 15px;
            font-size: $font-size-xsmall;
            line-height: $font-size-xsmall;
            color: $black;
            text-transform: none;
        }
        
        .apo-quote__author {
            font-weight: bold;
            margin-top: 0;
            margin-bottom: 5px !important;
            color: $black;
            font-family: $header-font-family;
            font-size: 14px;
            line-height: 18px;
        }
        
        .apo-quote__author-info {
            font-weight: normal;
            color: $primary-color;
        }
        
        .apo-quote__body {
            color: $primary-color;
        
            p, li {
                font-family: 'GT America', Arial, Helvetica, sans-serif;
                text-align: left;
                font-size: 14px;
                line-height: 18px;
            }

            p{
        
                &:first-of-type:before {
                    content: '«';
                }
        
                &:before {
                    content: '»';
                }
        
                &:last-of-type {
                    margin-bottom: 0;
                }
        
                &:last-of-type:after {
                    content: '»';
                }
        
                &:empty {
                    display: none;
                }
            }
        }
    }
}

.apo-quote--dark {
    .apo-quote__content {
        border-color: $dark-gray;
    }
}
