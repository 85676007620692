.apo-fancy-button {
    padding: 1rem;
    max-width: 50%;

    .show-on-triggered {
        display: none;
    }

    .triggered {
        .show-on-triggered {
            display: block;
        }
        .hide-on-triggered {
            display: none;
        }
    }
}

.apo-fancy-button__link {
    display: block;

    text {
        font-size: 5em;
        font-weight: 300;
    }
}
