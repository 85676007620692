$legal-notice-size: 150px + $font-size-small;

.apo-legal-notice-wrapper {
    width: $legal-notice-size;
    height: $legal-notice-size;
    padding: $font-size-small;
    border-radius: 50%;
    cursor: move;
}

.apo-legal-notice {

    position: relative;
    width: 100%;
    height: 100%;

    .apo-legal-notice__link {
        display: flex;
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
        }
    }

    .apo-legal-notice__hide {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15%;
        height: 15%;
        cursor: pointer;

        svg {
            width: 100%;
        }

    }

}