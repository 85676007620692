.minimal-button {
    color: $black;
    display: inline-block;
    
    &:after {
        content: '';
        width: 100%;
        display: block;
        border-bottom: 1px solid $black;
        margin-top: 3px;
    }
    
    &:hover {
        text-decoration: none;
        color: $primary-color;
        
        &:after {
            border-color: $primary-color;
        }
    }
}

.button {

    border-radius: 0;
    border: 2px solid $dark-gray;
    background-color: $light-gray;
    color: $dark-gray;
    // font-family: 'GT America Extended', Arial, Helvetica, sans-serif;
    font-size: $font-size-small;
    line-height: $font-size-small;
    padding: $font-size-small;
    
    &:hover {
        background-color: $dark-gray;
        color: $white;
    }
    
    &:focus {
        background-color: $light-gray;
    }
    
    @include breakpoint(small only) {
        font-size: $font-size-small;
        line-height: $font-size-small;
    }

}