.team {


    p {
        font-size: $font-size-normal;
        line-height: $line-height-normal;
        margin-bottom: 30px;
    }

    .team-member__name {
        font-weight: bold;
    }


    a {
        // text-decoration: none;
    }

}
