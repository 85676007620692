[data-panel] {

    margin-bottom: rem-calc(25);

    &[data-panel=left] {
        float: left;
        width: 25%;
        margin-right: rem-calc(25);
        
        [data-panel-content]  {
            float: right;
            width: 200%;
        }
    }
    
    &[data-panel=left-static] {
        float: left;
        width: 50%;
        margin-top: rem-calc(15);
        
        [data-panel-content]  {
            width: calc(100% - 15px);
            margin-right: rem-calc(15);
        }
    }
    
    &[data-panel=right]  {
        float: right;
        width: 25%;
        margin-left: rem-calc(25);
        
        [data-panel-content] {
            float: left;
            width: 200%;
        }
    }
    
    &[data-panel=right-static] {
        float: right;
        width: 50%;
        margin-top: rem-calc(15);
        
        [data-panel-content]  {
            width: calc(100% - 15px);
            margin-left: rem-calc(15);
        }
    }
    
    &[data-panel=centered]  {
        display: inline-block;
        max-width: 70%;  
        margin-top: rem-calc(15);
    }
    
    @include breakpoint(medium down) {
        
        &[data-panel=left] {
            width: 40%;
            
            [data-panel-content]  {
                width: 125%;
            }
        }
        
        &[data-panel=right]  {
            width: 40%;
            
            [data-panel-content] {
                width: 125%;
            }
        }

        &[data-panel=centered]  {
            max-width: 55%;  
        }
    }

    @include breakpoint(small down) {
        
        &[data-panel=left],
        &[data-panel=right] {
            float: none;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            
            [data-panel-content]  {
                float: none;
                width: 100%;
            }
        }
        &[data-panel=centered]  {
            max-width: 100%;  
        }
    
    }
}

