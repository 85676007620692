$cookies-size: 125px;

.apo-cookies-wrapper {
    width: $cookies-size;
    height: $cookies-size;
}

.apo-cookies {
    position: relative;
    background-color: $primary-color;
    width: 100%;
    height: 100%;

    .apo-cookies__title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: $font-size-xsmall;
        line-height: $font-size-medium;
        border-bottom: 1px solid $black;
        text-align: center;
        color: $black;
        font-family: $body-font-family;
    }

    .apo-cookies__body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        svg {
            width: 65%;
        }
    }

    .apo-cookies__action {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 20%;
        bottom: 0;
        border-color: $black;
        border-style: solid;

        svg {
            height: 75%;
        }

        &.apo-cookies__action--left {
            left: 0;
            border-width: 1px 1px 0 0;
        }
        
        &.apo-cookies__action--right {
            right: 0;
            border-width: 1px 0 0 1px;
        }
    }
}