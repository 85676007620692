.apo-section {

    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
        margin-bottom: rem-calc(50);
    }

}

.apo-section__title {

    max-width: 75%;
    margin-top: $font-size-xlarge * 1.5;
    margin-bottom: $font-size-xlarge;

    @include breakpoint(medium down) {
        max-width: 100%;
    }

    .apo-section__title-index {
        font-family: $header-font-family;
        font-size: $font-size-xlarge;
        line-height: $font-size-xlarge;
        font-weight: 300;
        color: $primary-color;
        display: block;
    }

    .apo-section__title-content {
        font-size: $font-size-large;
        line-height: $font-size-large;
        color: $dark-gray;
    }

}

.apo-section__subtitle {

    display: block;
    color: $primary-color;
    text-align: center;
    margin-bottom: $font-size-medium;
    margin-top: $font-size-large;

    h3 {
        display: inline-block;
        color: $primary-color;
        font-weight: 300;
        padding: 15px 0;
        font-size: $font-size-medium;
        line-height: $font-size-medium;
        font-family: $header-font-family;
        margin: 0;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 80%;
            height: 2px;
            margin: 0 auto;
            background-color: $primary-color;
        }

        &:before {
            margin-bottom: 15px;
        }
        &:after {
            margin-top: 15px;
        }
    }

}

.apo-section__body {
    p, li {
        text-align: justify;

        @include breakpoint (small down) {
            text-align: left;
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            color: darken($primary-color, 15%);
        }
    }

    li {
        text-align: justify;
    }

    ul {
        @include list-content(ul, right-arrow, '> ', $primary-color);

        li {
            padding-left: 1.25em;
            margin-bottom: 0.5em;
            margin-top: 0.3em;

            &:before {
                left: -1em;
                width: 0.25em;
                margin-left: -0.25em;
            }
        }

    }

    h4 {
        display: block;
        color: $primary-color;
        font-weight: 300;
        padding: 15px 0;
        font-size: $font-size-medium * 0.8;
        line-height: $font-size-medium * 0.8;
        font-family: $header-font-family;
        text-align: center;
        margin: 0;
        margin-top: $font-size-large;
        margin-bottom: $font-size-medium;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 2px;
            margin: 0 auto;
            background-color: $primary-color;
        }

        &:before {
            margin-bottom: 15px;
        }
        &:after {
            margin-top: 15px;
        }
    }

    h5 {
        font-family: $header-font-family;
        font-weight: bold;
        color: $primary-color;
        text-transform: uppercase;
        margin-top: $font-size-large;
        margin-bottom: $font-size-small;
        font-size: $font-size-small;
        line-height: $font-size-small;
    }

    h6 {
        font-weight: bold;
        color: $primary-color;
        margin-top: $font-size-medium;
        margin-bottom: $font-size-small;
        font-size: $font-size-small;
        line-height: $font-size-small;
    }
}

.apo-section__list {
    margin-top: -0.75em;

    @include breakpoint(small only) {
        margin-top: 0;
    }

    ul {
        list-style: none;
        margin-left: 15%;

        @include breakpoint(small only) {
            margin-left: 7.5%;
        }

        li{
            list-style: none;
            position: relative;
            border-bottom: 1px solid $medium-gray;
            padding-bottom: 1rem;
            margin-bottom: 1rem;

            &:before{
                content: '';
                display: block;
                position: absolute;
                left: -10%;
                top: 0.75em;
                width: 5%;
                height: 3px;
                background-color: $primary-color;

                @include breakpoint(small only) {
                    left: -7.5%;
                    width: 5%;
                }
            }

            a {
                display: inline-block;
                color: $primary-color;

                &:hover,
                &:focus,
                &:visited {
                    text-decoration: none;
                }

                &:after {
                    content: '';
                    display: block;
                    border-bottom: 1px solid $primary-color;
                }

                &:hover:after {
                    border-bottom-width: 2px;
                }

            }
        }

    }


    p {
        display: none;
    }

}
