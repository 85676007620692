.apo-resources__title {

    font-size: $font-size-normal;
    line-height: $line-height-normal;
    margin-bottom: 0.5em;

}

.apo-resources__content {
    padding-top: map_get($grid-column-gutter, medium) / 2;
    padding-bottom: map_get($grid-column-gutter, medium) / 2;
}

.apo-resource-item__wrapper {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: $white;

    &:before {
        content: '';
        display: block;
        padding-top: 60%;
    }
}

.apo-resource-item__content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    .apo-resource-item__icon {
        display: block;
        width: 80px;
        color: $primary-color;
        margin-right: 0.5rem;

        svg {
            * {
                fill: $primary-color;
            }
        }
    }

    .apo-resource-item__text {
        color: $primary-color;
        text-transform: uppercase;
        font-size: $font-size-large;
        font-family: $header-font-family-alt-2;
    }
    
    &:hover {
        .apo-resource-item__text {
            font-weight: bold;
        }
    }
}