article {
    padding: 45px 0;


    .vc_row {
        @include xy-grid-container;
    
        &[data-vc-full-width] {
            @include xy-grid-container(100%);
        }
    
        &[data-vc-stretch-content] {
            @include xy-grid-container(100%, 0);
        }
    }

    .wpb_text_column {
        margin-bottom: $font-size-xlarge;
    }

    h1 {
        color: $secondary-color;
        font-family: $blockquote-font-family;
        font-size: $font-size-xlarge;
        line-height: $line-height-xlarge;
    }
    
    h3 {
        font-size: $font-size-normal;
        line-height: $line-height-normal;
        margin-bottom: $font-size-large;
    }
    
    p, ul {
        margin-bottom: $font-size-large;

        &:last-child {
            margin-bottom: 0
        }
    }

    p, li {
        font-size: $font-size-normal;
    }
    

    ul {
        list-style: none; /* Remove list bullets */
        padding: 0;
        margin-left: 0;
    }

    li {
        margin-bottom: 0.5em;
        padding-left: 1.5em;

        &:before {
            content: '__';
            width: 1.5em;
            margin-left: -1.5em;
            display: inline-block;
            position: relative;
            top: -0.3em;
        }
    }

    .apo-info-text {
        p {
            font-size: $font-size-small;
            margin-bottom: 0;
            line-height: 1.4
        }
    }
}