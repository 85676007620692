$header-height: 120px;
$header-height-stuck: 60px;

.header {
    display: flex;
    color: $primary-color;
    background: inherit;
    transition: all 300ms;
}

.header__wrapper {
    position: relative;
    width: 100%;
    height: $header-height-stuck;
    border-bottom: 1px solid $primary-color;
    background: inherit;
    transition: all 300ms;
    z-index: 100;
    
    @include breakpoint (large) {
        height: $header-height;
    }

    >.grid-container {
        display: flex;
        flex-flow: row nowrap;
        height: 100%;
        align-items: center;
        justify-content: space-between;
    }
}

.header__brand {

    .header__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        svg {
            height: $font-size-xlarge;
            
            @include breakpoint (large) {
                height: $font-size-xxlarge;
            }

            path {
                fill: $primary-color;
            }
        }
    }

}

.header__menu-bar {

    display: flex;
    flex-grow: 1;

}

.header__menus {

    flex-grow: 1;
    display: flex;
    justify-content: space-around;

    .nav-menu {
        display: flex;
        align-items: stretch;
    }

}

.header__toolbar {

    display: flex;

}

.header__search {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    .input-group {
        margin-bottom: 0;
    }

    .input-group-label {
        background-color: transparent;
        border-right: 0;
        border-radius: 0;
        padding: 0 0.5rem;
    }

    .input-group-field {
        box-shadow: none;
        background-color: transparent;
        border-left: 0;
        border-color: rgba($primary-color, 0.35);
        line-height: 1;
    }

    .input-group-button {

        button {
            display: flex;
            align-items: center;
            border: 1px solid rgba($primary-color, 0.35);
            border-right: 0;
            background-color: transparent;
            padding: 0 0.5rem;

            svg {
                * {
                    fill: rgba($primary-color, 0.35);
                }
            }

            &:hover,
            &:focus {

                svg {
                    * {
                        fill: rgba($primary-color, 0.65);
                    }
                }

            }
        }
    }
}

.header__social {
    display: flex;
}

.header__menus--mobile {
    flex-flow: column nowrap;
}

.header__mobile-menu {
    position: absolute;
    background-color: $body-background;
    top: 100%;
    left: 0;
    width: 100%;
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    
    .header__search {
        justify-content: center;
        margin-right: 0;
        padding: (map_get($grid-column-gutter, medium) / 2);
        // border-bottom: 1px dotted $primary-color;
        
        form {
            width: 100%;
        }
    }
    
    .header__social {
        justify-content: center;
        padding: map_get($grid-column-gutter, medium) / 2;
        // border-top: 1px dotted $primary-color;
    }
}

.rainbow {
    position: absolute;
    height: 100%;
    width: 13px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    left: 0;
    top: 0;
    padding: $font-size-large 0;

    .rainbow__item {
        height: 7px;
        width: 100%;
        background-color: $primary-color;
    }

    .rainbow__item--first {
        background-color: $rainbow-first-color;
    }

    .rainbow__item--second {
        background-color: $rainbow-second-color;
    }

    .rainbow__item--third {
        background-color: $rainbow-third-color;
    }

    .rainbow__item--fourth {
        background-color: $rainbow-fourth-color;
    }

    .rainbow__item--fifth {
        background-color: $rainbow-fifth-color;
    }

    .rainbow__item--sixth {
        background-color: $rainbow-sixth-color;
    }
}

.header__wrapper.is-stuck {
    height: $header-height-stuck;

    .header__brand {

        .header__logo {

            svg {
                height: $font-size-xlarge;
            }
        }
    }

    .rainbow {
        padding: 2px 0;
    }
}
