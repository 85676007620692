@mixin list-content($type: ol, $list_style: decimal, $separator: '. ', $color: #000){

    @if $type ==  ol {
        counter-reset: $list_style;
    }
    margin-left: 0;

    >li {
        display: block;
        padding-left: 2.5em;
        overflow: auto;

        &:before {
            @if $type ==  ol {
                content: counter($list_style, $list_style) $separator;
                counter-increment: $list_style;
            } @else {
                content: $separator;
            }
            position: relative;
            left: -0.5em;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            color: $color;
        }
    }

}

@mixin list($type: ol, $list_style: decimal, $separator: '. ', $color: #000){

    #{$type}.#{$list-style} {

        @include list-content($type, $list_style, $separator, $color);

    }

}

