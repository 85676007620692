.apo-figure {
    font-size: $font-size-small;
    line-height: $font-size-small;

    figcaption {
        color: $primary-color;
        font-family: $header-font-family;
        font-weight: 500;
    }
}

.apo-figure--left {

    figcaption {
        text-align: left;
    }

}
.apo-figure--right {

    figcaption {
        text-align: right;
    }

}

.apo-figure--top {

    figcaption {
        margin-bottom: rem-calc(10);
    }

}

.apo-figure--bottom {

    figcaption {
        margin-top: rem-calc(10);
    }

}
