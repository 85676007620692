.blog-search__header,
.blog-search__footer {
    padding: $font-size-large 0;
}

.blog-search__title {
    text-transform: uppercase;
    font-size: $font-size-large;
    color: $primary-color;
    font-weight: 300;

    span {
        font-weight: 500;
    }
}

.tease {

    padding: $font-size-xlarge 0;
    border-bottom: 1px solid $primary-color;
    
    &:first-child {
        border-top: 1px solid $primary-color;
    }

}

.tease__post-type {

    display: flex;
    font-weight: 400;

    span {
        display: block;
        border: 1px solid $secondary-color;
        color: $secondary-color;
        padding: 0.25em 0.5em;    
        border-radius: 0.25em
    }

}