.apo-reveal {
    visibility: hidden;
    opacity: 0;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}

.apo-trigger {
    display: block;
    width: 30px;
    height: 30px;

    svg {
        width: 100%;
        height: auto;

        * {
            fill: $primary-color;
        }
    }
}

.apo-trigger--menu {

    .open {
        opacity: 1;
        transition: all 200ms;
    }
    
    .close {
        transform-origin: 50% 50%;
        transition: all 200ms;
        opacity: 0;
    }
    
    &.triggered {
        .close {
            opacity: 1;
        }
        .open {
            opacity: 0;
        }
        .close--up {
            transform: rotate(-45deg);
        }

        .close--down {
            transform: rotate(45deg);
        }
    }

}