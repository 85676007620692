.apo-highlight {

    padding: rem-calc(20) rem-calc(30);

    h5 {
        color: $primary-color;
        font-size: rem-calc(18);
        line-height: rem-calc(32);
        letter-spacing: 0;
        margin-top: 0;
        margin-bottom: rem-calc(5);
    }
    
    h6 {
        color: $white;
        font-size: rem-calc(25);
        line-height: rem-calc(35);
        margin-bottom: rem-calc(15);
        
        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .apo-highlight__link {
        font-family: $header-font-family;
        display: inline-block;
        font-size: rem-calc(22);
        line-height: rem-calc(31);
        margin-top: auto;
    }
    
}

.apo-highlight--dark {
    
    background-color: $black;

    h6 {
        color: $white;
    }

    .apo-highlight__link {
        color: $light-gray;
    }
}

.apo-highlight--light {
    
    background-color: $white;

    h6 {
        color: $black;
    }

    .apo-highlight__link {
        color: $dark-gray;
    }
}

.apo-highlight--image {
    
    background-size: cover;
    background-position: contain;

    h6 {
        color: $black;
    }

    .apo-highlight__link {
        color: $white;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
    }
}

.apo-highlight--underline {

    h6 {
        text-decoration: underline $primary-color;
    }

}

.apo-highlight--large {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    h6 {
        font-size: rem-calc(35);
        line-height: rem-calc(43);
        margin-bottom: rem-calc(60);
    }

    .apo-highlight__link {
        font-size: rem-calc(30);
        line-height: rem-calc(38);
    }
}
