.ac-experience {

    background-color: $light-gray;
    padding: $font-size-small;
    margin-bottom: $font-size-xlarge;

}

.ac-experience__title {

    h4 {
        font-family: 'GT America Extended', Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: $font-size-small;
        line-height: $font-size-small;
        text-transform: uppercase;
        margin-bottom: $font-size-medium;
    }
}

.ac-experience__body {

    margin-bottom: $font-size-medium;

    ul {
        list-style-image: url('img/list-arrow.png');

        li {
            margin-bottom: 0.5em;
            margin-top: 0.3em;
        }
    }
}

.ac-experience__testimonial-author {
    
    font-family: $header-font-family;
    font-weight: bold;
    font-size: $font-size-small;
    line-height: $font-size-small;

    span {
        font-weight: 300;
    }

}

.ac-experience__testimonial-content {
    color: $primary-color;

    p{
        font-family: 'GT America', Arial, Helvetica, sans-serif;

        &:first-of-type:before {
            content: '«';
        }

        &:before {
            content: '»';
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &:last-of-type:after {
            content: '»';
        }

        &:empty {
            display: none;
        }
    }
}
