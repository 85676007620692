.ac-chapter-header {
    position: relative;
    overflow: hidden;
    height: 65vh;
    
    // &:before {
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     padding-top: 40%;
    // }
}

.ac-chapter-header__video {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $light-gray;
    // height: auto;
    // transform: translateY(-50%);
    
    video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        height: 100%;
    }
}

.ac-chapter-header__title {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    h1 {
        font-size: $font-size-xlarge * 0.75;
        line-height: $font-size-xlarge * 0.75;
        display: inline;
        color: $white;
        background-color: rgba($black,0.75);
        font-weight: normal;
    }
}
