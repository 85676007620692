.home-paint {

    width: 100%;
    height: 100%;

    .home-paint__wrapper {
        width: 100%;
        height: 100%;
    }

    .home-paint__canvas {

        &.style-a-5 { cursor: url('img/cursor-a-small.png') 2 2, auto; }
        &.style-a-10 { cursor: url('img/cursor-a-medium.png') 5 5, auto; }
        &.style-a-15 { cursor: url('img/cursor-a-large.png') 7 7, auto; }
        &.style-a-20 { cursor: url('img/cursor-a-xlarge.png') 10 10, auto; }

        &.style-b-5 { cursor: url('img/cursor-b-small.png') 2 2, auto; }
        &.style-b-10 { cursor: url('img/cursor-b-medium.png') 5 5, auto; }
        &.style-b-15 { cursor: url('img/cursor-b-large.png') 7 7, auto; }
        &.style-b-20 { cursor: url('img/cursor-b-xlarge.png') 10 10, auto; }

        &.style-c-5 { cursor: url('img/cursor-c-small.png') 2 2, auto; }
        &.style-c-10 { cursor: url('img/cursor-c-medium.png') 5 5, auto; }
        &.style-c-15 { cursor: url('img/cursor-c-large.png') 7 7, auto; }
        &.style-c-20 { cursor: url('img/cursor-c-xlarge.png') 10 10, auto; }

        &.style-d-5 { cursor: url('img/cursor-d-small.png') 2 2, auto; }
        &.style-d-10 { cursor: url('img/cursor-d-medium.png') 5 5, auto; }
        &.style-d-15 { cursor: url('img/cursor-d-large.png') 7 7, auto; }
        &.style-d-20 { cursor: url('img/cursor-d-xlarge.png') 10 10, auto; }

        &.style-e-5 { cursor: url('img/cursor-e-small.png') 2 2, auto; }
        &.style-e-10 { cursor: url('img/cursor-e-medium.png') 5 5, auto; }
        &.style-e-15 { cursor: url('img/cursor-e-large.png') 7 7, auto; }
        &.style-e-20 { cursor: url('img/cursor-e-xlarge.png') 10 10, auto; }

        &.style-f-5 { cursor: url('img/cursor-f-small.png') 2 2, auto; }
        &.style-f-10 { cursor: url('img/cursor-f-medium.png') 5 5, auto; }
        &.style-f-15 { cursor: url('img/cursor-f-large.png') 7 7, auto; }
        &.style-f-20 { cursor: url('img/cursor-f-xlarge.png') 10 10, auto; }

        &.style-eraser-5 { cursor: url('img/cursor-eraser-small.png') 3 3, auto; }
        &.style-eraser-10 { cursor: url('img/cursor-eraser-medium.png') 5 5, auto; }
        &.style-eraser-15 { cursor: url('img/cursor-eraser-large.png') 8 8, auto; }
        &.style-eraser-20 { cursor: url('img/cursor-eraser-xlarge.png') 10 10, auto; }
        
        &.style-default-5 { cursor: url('img/cursor-default-small.png') 3 3, auto; }
        &.style-default-10 { cursor: url('img/cursor-default-medium.png') 5 5, auto; }
        &.style-default-15 { cursor: url('img/cursor-default-large.png') 8 8, auto; }
        &.style-default-20 { cursor: url('img/cursor-default-xlarge.png') 10 10, auto; }
    }
    
    .home-paint__controls {
        position: absolute;
        top: $panel-size + 30px;
        right: $panel-size + 30px;
        
        .control-group {
            width: 70px;
            display: flex;
            flex-flow: row wrap;
            margin-bottom: 1px;
        }
    
        .control {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white;
            border-color: $black;
            border-style: solid;
            border-width: 0 1px 1px 0;
            cursor: pointer;
    
            &:nth-of-type(2n) {
                border-width: 0 0 1px 0;
            }
    
            svg {
                height: 62%;
            }
        }
    
    }
}