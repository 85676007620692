blockquote {

    font-family: $blockquote-font-family;
    border: 0;
    padding: 0;
    font-size: $font-size-medium;
    color: $secondary-color;
    line-height: 1.2;

    p {
        font-family: inherit;
        color: inherit;
        line-height: inherit;

        &:first-of-type {
            &:before {
                content: '\201C';
            }
        }

        &:last-of-type {
            &:after {
                content: '\201D';
            }
        }
    }

    .author {
        font-size: 0.7em;
    }

}
