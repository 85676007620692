.apo-aside {
    font-size: $font-size-small;
    line-height: $font-size-small;
    
    .apo-aside__content {
        padding: 15px;
        border: 2px solid $dark-gray;
    }
}

.apo-aside__body {
    color: $dark-gray;

    h1, h2, h3, h4, h5, h6 {
        font-size: 14px;
        line-height: 18px;
        font-family: $header-font-family;
        font-weight: bold;
        color: $black;
        text-align: left;
        margin: 0;
        margin-bottom: $font-size-small / 2;
        padding: 0;

        &:before,
        &:after {
            display: none;
        }
    }
    
    p, li {
        font-family: 'GT America', Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
    }
    
    p{
        &:last-of-type {
            margin-bottom: 0;
        }

        &:empty {
            display: none;
        }
    }

}
