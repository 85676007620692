$index-bg-color: rgba($white, 0.9);
$index-transition-time: 400ms;

.chapter {
    position: relative;
}

.chapter-index {
    position: fixed;
    width: 33.33%;
    min-width: 520px;
    max-width: 100%;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    display: flex;
    align-items: center;
    transition: transform $index-transition-time;
    background-color: transparent;
    
    @include breakpoint (small only) {
        top: 0;
        left: initial;
        right: 0;
        display: block;
        transform: translate(0%, 0%);
        min-width: 100px;
        z-index: 100;
    }
}

.chapter-index__trigger {

    position: relative;
    left: 36px;
    transition: transform $index-transition-time, left $index-transition-time;

    button {
        padding: rem-calc(10);
        color: $primary-color;
        font-size: $font-size-medium;
        line-height: $font-size-medium;
        background-color: $index-bg-color;
        border-radius: 0;

        i {
            transform: rotate(0deg);
            transition: transform $index-transition-time;
        }

        &:hover {
            background-color: $primary-color;
            color: $white;

            i {
                animation: none;
            }
        }

        &:focus {
            outline: none;
        }
    }

    @include breakpoint (small only) {
        left: initial;
        padding: $font-size-small;
        display: flex;
        justify-content: flex-end;

        button {
            background-color: $primary-color;
            color: $white;
            font-size: $font-size-small;
            line-height: $font-size-small;
            padding: rem-calc(5) rem-calc(8);

            i {
                animation: none;
            }

            &:hover {
                background-color: darken($primary-color, 15%);
            }
        }
    }
    
}
.chapter-index__content {
    margin: 0;
    // padding-right: map-get($grid-column-gutter, medium) /2;
    padding-left: map-get($grid-column-gutter, medium) /2;
    // transform: translateX(-130%);
    position: relative;
    left: 36px;
    transition: transform $index-transition-time, left $index-transition-time;
    
    @include breakpoint (small only) {
        display: flex;
        flex-flow: column;
        justify-content: center;
        position: absolute;
        left: initial;
        top: 0;
        right: 0;
        height: 100vh;
        background-color: $white;
        opacity: 0;
        visibility: hidden;
        padding-right: map-get($grid-column-gutter, medium) /2;
    }
}

.chapter-index__node {
    list-style: none;
    font-size: $font-size-xsmall;
}

.chapter-index__node--first-level {
    font-family: $header-font-family;
    font-weight: bold;
    font-size: $font-size-small;
    line-height: $line-height-xsmall;
    border-top: 1px solid $primary-color;
    margin-top: rem-calc(3);
    padding: rem-calc(10);
    padding-bottom: rem-calc(5);
    
    &:first-of-type {
        margin-top: 0;
    }
}

.chapter-index__node--second-level {
    padding-left: rem-calc(20);
    font-size: $font-size-xsmall;
}

.chapter-index.active {

    background-color: $index-bg-color;
    transform: translate(0%, -50%);
    
    
    .chapter-index__trigger {
        left: 0;
        
        button {
            background-color: transparent;

            i {
                animation: none;
                transform: rotate(180deg);
            }
            
            &:hover {
                background-color: $primary-color;
            }
        }
        
    }
    
    .chapter-index__content {
        left: 0;
        // transform: translateX(0%);
    }
    
    @include breakpoint (small only) {
        transform: translate(0%, 0%);
        left: initial;
        background-color: transparent;
        min-width: 100%;

        .chapter-index__trigger {
            display: none;
            top: 0;
            
            button {
                background-color: $primary-color;
    
                i {
                    animation: none;
                    transform: rotate(180deg);
                }
                
                &:hover {
                    background-color: darken($primary-color, 15%);
                }
            }
            
        }
        
        .chapter-index__content {
            top: 0;
            background-color: $white;
            height: 100vh;
            opacity: 1;
            visibility: visible;
            
        }
    }
    
}

.chapter__pdf {
    position: fixed;
    right: $font-size-large;
    bottom: $font-size-large;
    
    @include breakpoint(medium down) {
        right: $font-size-medium;
        bottom: $font-size-medium;
    }
    
    @include breakpoint(small down) {
        right: $font-size-small;
        bottom: $font-size-small;
    }

}

