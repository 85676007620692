@mixin animation-spin($type, $speed, $direction) {
    .animation__spin--#{$type}--#{$direction} {
        animation-name: spin;
        animation-duration: $speed;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-direction: $direction
    }

}

@include animation-spin('ultra-slow', 20000ms, normal);
@include animation-spin('ultra-slow', 20000ms, reverse);

@include animation-spin('slow', 15000ms, normal);
@include animation-spin('slow', 15000ms, reverse);

@include animation-spin('normal', 10000ms, normal);
@include animation-spin('normal', 10000ms, reverse);

@include animation-spin('fast', 5000ms, normal);
@include animation-spin('fast', 5000ms, reverse);

@include animation-spin('ultra-fast', 1000ms, normal);
@include animation-spin('ultra-fast', 1000ms, reverse);

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}