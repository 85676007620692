.apo-band {

    display: block;

    .apo-band__wrapper {
        position: relative;
        height: 100%;
        background-position: left 80%;
        background-repeat: no-repeat;
        background-size: 40%;
        padding-top: 50%;
        
        @include breakpoint(medium) {
            padding-top: 24%;
        }
    }

    .apo-band__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        
    }

    .apo-band__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        .grid-container,
        .grid-x {
            height: 100%;
        }

    }
    
    .apo-band__body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        h3 {
            line-height: 1;
            text-transform: uppercase;
            font-family: $header-font-family-alt-2;
            font-weight: bold;
            text-align: center;
            font-size: $font-size-large;
            
            @include breakpoint(medium) {
                font-size: $font-size-xlarge * 1.25;
            }

            @include breakpoint(large) {
                font-size: $font-size-xlarge * 1.5;
            }
            
            @include breakpoint(xlarge) {
                font-size: $font-size-xlarge * 2.2;
            }

            @include breakpoint(xxlarge) {
                font-size: $font-size-xlarge * 2.9;
            }
        }
    
        .apo-band__title {
            color: $primary-color;
            display: block;
        }
    
        .apo-band__subtitle {
            color: $white;    
        }

    }

}