.projects {

    padding: 65px 0;

}

.projects__filter {

    margin-bottom: 65px;

    .menu {
        justify-content: center;

        li {
            font-size: $font-size-xlarge;
            line-height: $line-height-xlarge;

            a {
                padding: 10px;
            }

            &.filter-item--current {
                font-weight: bold;

                a {
                    text-decoration: none;
                }
            }
        }
    }

}

.project-item {

    position: relative;
    display: block;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        padding-top: 65%;
    }

    .project-item__overlay,
    .project-item__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .project-item__overlay {
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        background-color: rgba($black, 0.9);
        border: 1px solid $white;
        transition: all 300ms;
    }

    .project-item__client {
        font-size: $font-size-large;
        line-height: $line-height-large;
        margin-bottom: $font-size-large;
        text-decoration: underline;
    }

    .project-item__description {
        padding: 0 60px;
        font-size: $font-size-small;
        line-height: $line-height-small;
    }

    .project-item__title {
        font-size: $font-size-xlarge;
        line-height: $line-height-xlarge;
        text-decoration: underline;
    }

    &:hover {
        font-family: $body-font-family;
        font-weight: normal;

        .project-item__overlay {
            opacity: 1;
            visibility: visible;
        }
    }

}

.project-post {

    padding: 95px 0;

    .project-post__thumbnail {
        margin-bottom: 45px;
    }

    .project-post__title {

        .apo-marquee {
            overflow: hidden;
        }

        h1 {
            font-size: 65px;
            margin-bottom: 80px;
            font-family: $header-font-family-alt;
            font-weight: normal;
        }
    }

    .project-post__intro {
        font-size: $font-size-xlarge;
        line-height: $line-height-xlarge;
    }

    .project-post__card {
        text-align: right;

        .post-card-trigger {
            font-size: $font-size-xlarge;
            line-height: $line-height-xlarge;

            .show-on-triggered {
                display: none;
            }

            &.triggered {
                .show-on-triggered {
                    display: initial;
                }
                .hide-on-triggered {
                    display: none;
                }
            }
        }

    }

    .post-card {
        padding: $font-size-small;
        border: 1px solid $white;
        text-align: left;


        .post-card__item {
            font-size: $font-size-small;
            padding: $font-size-small 0;
            border-bottom: 1px solid $white;

            &:last-of-type {
                border-bottom: 0;
            }
            p {
                line-height: $font-size-small;
                margin-bottom: $font-size-small;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .project-post__page-link {

        font-size: $font-size-xsmall;
        position: fixed;
        top: 50%;
        width: $font-size-xsmall;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            transform: rotate(-90deg);
            white-space: nowrap;
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover {
                font-family: $body-font-family;
            }
        }

        span {
            text-decoration: underline;
        }

        .arrow {
            transform: rotate(90deg);
        }
    }

    .project-post__next {
        right: $font-size-xsmall;

        .arrow {
            &:before {
                content: '\2192\0020';
                font-feature-settings: "ss03"
            }
        }
    }

    .project-post__prev {
        left: $font-size-xsmall;

        .arrow {
            &:before {
                content: '\2190\0020';
                font-feature-settings: "ss03"
            }
        }
    }

    .project-post__footer {
        padding: 60px 0;
        font-size: $font-size-xlarge;
    }

}
