.apo-slider {

    .apo-slider__wrapper {
        background-color: $primary-color;
        line-height: 0;
    }

    

    .apo-slider__arrow {
        position: absolute;
        bottom: 0;
        width: $font-size-small;
        transform: translateY(-25%);
        z-index: 5;
        visibility: hidden;
        opacity: 0;
        transition: all 300ms;
        
        @include breakpoint(medium) {
            width: $font-size-normal;
            transform: translateY(-50%);
        }
        
        @include breakpoint(large) {
            width: $font-size-medium;
            transform: translateY(-100%);
        }
        
        svg {
            width: 100%;
            * {
                stroke: $body-background;
            }
        }
    }
    
    .apo-slider__arrow--prev {
        left: $font-size-medium / 2;
        
        @include breakpoint(medium) {
            left: $font-size-medium;
        }
        
        @include breakpoint(large) {
            left: 2 * $font-size-medium;
        }
    }
    
    .apo-slider__arrow--next {
        right: $font-size-medium / 2;
        
        @include breakpoint(medium) {
            right: $font-size-medium;
        }
        
        @include breakpoint(large) {
            right: 2 * $font-size-medium;
        }
    }

    &:hover {

        .apo-slider__arrow {
            visibility: visible;
            opacity: 1;
        }

    }

}

.apo-slider-item {
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 60%;

    .apo-slider-item__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .apo-slider-item__anchor,
    .apo-slider-item__overlay,
    .apo-slider-item__content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .apo-slider-item__anchor {
        z-index: 3;
    }

    .apo-slider-item__overlay {
        z-index: 1
    }
    
    .apo-slider-item__content {
        padding: 5%;
        text-align: center;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    
        @include breakpoint(medium) {
            padding: 5%;
        }

        @include breakpoint(large) {
            padding: 10%;
        }
    }
    
    .apo-slider-item__title {
        background-color: $white;
        text-transform: uppercase;
        font-family: $header-font-family-alt-2;
        font-size: $font-size-medium;
        line-height: $line-height-medium;
        font-weight: normal;
        color: $primary-color;
        text-align: center;
        display: inline;
    }
    
    blockquote {
        font-size: $font-size-normal;
        text-align: left;
        
        @include breakpoint (medium) {
            font-size: $font-size-medium;
        }
        
        @include breakpoint (large) {
            font-size: $font-size-xlarge;
        }
    }
}

.apo-slider-item--image {

    .apo-slider-item__overlay {
        background: linear-gradient(60deg, rgba($primary-color, 1), rgba($primary-color, 0.5), rgba($secondary-color, 0.5), rgba($secondary-color, 1) )
    }

}

