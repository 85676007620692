.footer {
    border-top: 1px solid $primary-color;
}

.footer__wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: $font-size-xsmall;
    line-height: $line-height-small;

    h4 {
        color: $secondary-color;
        font-size: $font-size-small;
        margin-bottom: $font-size-small;
        text-transform: uppercase;
        font-family: $header-font-family-alt;

        a {
            color: inherit;
        }
    }

    p {
        font-size: $font-size-xsmall;
        // line-height: 1.3;
        margin-bottom: 0;

        a {
            color: $secondary-color;
        }
    }

    .action-sidebar {
        text-align: center;
        
        @include breakpoint(medium) {
            text-align: right;
        }
    }

}

.footer__brand {
    background-color: $black;

    >.grid-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
