.pagination-block {
    display: flex;
    justify-content: center;
    font-size: $font-size-xlarge;
    padding: 100px 0;
}

.pagination {

    width: 100%;
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
        display: none;
    }
    
    a, button, span {
        text-decoration: none;
        font-size: $font-size-xlarge;
        color: $primary-color;
        background-color: transparent;
        
        &:hover {
            background-color: transparent;
        }
    }
    
    .current {
        color: $secondary-color;
        background-color: transparent;

        span {
            padding: 0;
        }
    }

    .disabled {
        padding: 0;
        opacity: 0;
        visibility: hidden;
    }

}