

.front-page {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
    width: 100%;

    .header {
        display: flex;
        background-color: transparent;
        color: $white;
        
        a {
            color: $white;
        }
    }

    .header__wrapper {
        height: 100%;
        background-color: transparent;
        border: 0;
    }

    .header__brand {
        width: $panel-size - 1px;
        border-right: 1px solid $white;

        .apostrophe {
            width: 1rem;
            path {
                fill: $white;
            }
        }
    }

    .header__links {

        display: flex;
    
        .links-menu {
            display: flex;
            border-left: 1px solid $black;

            >.menu {
                
                >li {
                    width: $panel-size - 1px;
                    border-left: 1px solid $white;

                    > a {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        color: $white;
                        
                        span {
                            transform: rotate(45deg);
                        }

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }

            .menu {
                &.is-dropdown-submenu {
                    background-color: $black;
                }
            }

        }

    }

    .header-link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;

        >span {
            display: flex;
        }

        .header-link__text {
            display: none;
            transform: rotate(45deg);
            font-size: $font-size-xsmall;
        }
        
        &:hover {
            .header-link__icon {
                display: none;
            }

            .header-link__text {
                display: flex;
            }
        }
    }

    .header__contact {
    
        .envelope {
            height: $font-size-medium;
            path {
                fill: $white;
            }
        }

    }
    
    .header__menus {
    
        display: flex;
        flex-grow: 1;
    
        .menu {
            li {
                font-size: $font-size-medium;
            }
        }
        
        .main-menu {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .social-menu {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
    }

    .front-page__content {
        // position: relative;
    }

    .front-page__conditional-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .front-page-section {
        position: relative;
        font-size: $font-size-large;
        line-height: $line-height-large;
        padding: $panel-size;
        display: flex;
        align-items: center;
        height: 100%;
    }

    .front-page-section__close {
        position: absolute;
        top: $font-size-large;
        right: $font-size-large;

        // svg {

        //     width: 100%;
        //     .close-icon {
        //         fill: $white;
        //     }
        // }
    }
}


.panel-group {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
}

.panel {
    border-color: $white;
    border-style: solid;
    border-width: 1px;

    .panel__content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
}

.panel--top {
    height: $panel-size;
    width: 100%;
}

.panel--left {
    width: $panel-size;
    height: calc(100% - 80px);
    border-width: 0 1px 1px 1px;

    h2 {
        transform: rotate(-90deg);
        white-space: nowrap;
        font-size: $font-size-medium;
        line-height: $line-height-medium;
    }
}

.panel--right {
    width: $panel-size;
    height: calc(100% - 80px);
    border-width: 0 1px;
    
    h2 {
        transform: rotate(-90deg);
        white-space: nowrap;
        font-size: $font-size-medium;
        line-height: $line-height-medium;
    }
}

.panel--bottom {
    height: $panel-size;
    width: 100%;
    border-width: 1px 1px 1px 0;
}

.panel--center {
    height: calc(100% - 80px);
    width: calc(100% - 80px);
    border-width: 0;
}