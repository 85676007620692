.apo-video-slider {

    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        display: block;
        padding-top: 43%;
    }

    video {
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    .apo-video-slider__controls {
        position: absolute;
        top: map-get($grid-column-gutter, medium);
        left: map-get($grid-column-gutter, medium);
        padding: rem-calc(10);
        background-color: rgba($black, 0.25);
        color: $white;
        font-family: $header-font-family;
        font-size: rem-calc(16);
        line-height: rem-calc(28);
        text-transform: uppercase;
        
        a {
            display: inline-block;
            color: inherit;

            &:hover {
                text-decoration: line-through $primary-color;
            }
        }
        
        .apo-video-slider__pause {
            margin-right: rem-calc(10);
        }

        .apo-video-slider__mute {
            margin-left: rem-calc(10);
        }

    }

    .hide-on-playing {
        display: none;
    }
    
    .hide-on-sound {
        display: none;
    }
    
    .show-on-playing {
        text-decoration: line-through currentColor;
    }
    
    .show-on-sound {
        text-decoration: line-through currentColor;
    }
}

.apo-video-slider.is-playing {
    
    .show-on-playing {
        // display: initial;
        text-decoration: none;
    }
    
    // .hide-on-playing {
        //     display: none;
        // }
        
    }
    
.apo-video-slider.with-sound {
        
    .show-on-sound {
        display: initial;
        text-decoration: none;
    }

    // .hide-on-sound {
    //     display: none;
    // }

}