$fa-font-path: "./fonts/webfonts";

$page-content-padding-top: 80px;
$panel-size: 80px;

$font-size-xxlarge: 60px;
$font-size-xlarge: 34px;
$font-size-large: 28px;
$font-size-medium: 24px;
$font-size-normal: 20px;
$font-size-small: 16px;
$font-size-xsmall: 12px;

$line-height-xxlarge: 74px;
$line-height-xlarge: 42px;
$line-height-large: 30px;
$line-height-medium: 28px;
$line-height-normal: 24px;
$line-height-small: 18px;
$line-height-xsmall: 16px;

$rainbow-first-color: #BB3029;
$rainbow-second-color: #EA9A29;
$rainbow-third-color: #F4D520;
$rainbow-fourth-color: #008F69;
$rainbow-fifth-color: #154287;
$rainbow-sixth-color: #70297D;
