.wp-block {
    max-width: $global-width;
}

.editor-post-title__block {

    .editor-post-title__input {
        font-family: $body-font-family;
        color: $body-font-color;

        &:focus {
            background-color: transparent;
        }
    }
    
    &.is-selected {
        .editor-post-title__input {
            background-color: transparent;
            color: $body-font-color;
        }
    }

}